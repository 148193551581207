import React, { useState, useEffect, PropTypes } from "react"
import Autosuggest from 'react-autosuggest';
import $ from "jquery"
import { navigate, useMatch } from "@reach/router"
import _ from "lodash"
import axios from 'axios';
import { removeSpecialChar, hyphenToCaptilize, QueryList } from "../utils/property-search"
import './assets/styles/_index.scss';

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => `${suggestion.districtCountyTown} ${(suggestion.location) ? `(${suggestion.location})` : ''}`;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div>
    {`${suggestion.districtCountyTown} ${(suggestion.location) ? `(${suggestion.location})` : ''}`}
  </div>
);




class SearchResultBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props?.value,
      suggestions: [],
      areas: props?.areaList || [],
      aliasVal: "",
      isChages: false
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      aliasVal: newValue,
      isChages: true
    });
    if (this?.props?.setValue) {
      //this.props.setValue(newValue)
    }
  };
  onKeyDown = (event) => {
    // this.setState({
    //   value: newValue,
    //   aliasVal: newValue,
    //   isChages: true
    // });
    if (this?.props?.setValue && event.key == "Enter") {
      let url = "/property/for-sale/";
      if (this.props.type == "Residential_Sales") {
        url = "/property/for-sale/"
      }
      else if (this.props.type == "Residential_Lettings") {
        url = "/property/to-rent/"
      }
      else if (this.props.type == "New_Homes") {
        url = "/property/new-homes/for-sale/"
      }
      if(this.state.value) {
        var val = this.state.value.replace(/^\s+|\s+$/gm,'').split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase()
        url += "in-" + removeSpecialChar(val) + "/"
      }
      else {
        if(this.props.type == "Residential_Lettings") {
          url += ""
        }
        else {
          url += "in-chipping-ognar-essex/"
        }
      }
      //this.props.setValue(this.state.value, false)
      //navigate(url);
    }
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  suggestionSelected = (e, { suggestion }) => {

    if (suggestion) {
      this.setState({
        value: `${suggestion.districtCountyTown} ${(suggestion.location) ? `(${suggestion.location})` : ''}`,
        aliasVal: suggestion.districtCountyTown,
      });
      if (this?.props?.setValue) {
        this.props.setValue(suggestion.districtCountyTown, TextTrackCueList)
      }
      localStorage.setItem("searchCounty", suggestion.county);
    }
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  getitems = async url => {
    try {
      const { data } = await axios.get(url, {})
      // this.setState({ areas: data })
      let dummyData = [
        {
          name: "Terraced",
          location: '',
          latLng: '51.7384, -1.23815'
        },
        {
          name: "terraced house",
          location: '',
          latLng: '51.7384, -1.23815'
        }
      ]
      this.setState({ areas: data })
      if (this?.props?.setList)
        this.props.setList(data)
    } catch (error) {
      console.error(error)
    }
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.state.areas.filter(area =>
      area.districtCountyTown.toLowerCase().slice(0, inputLength) === inputValue
    );
  };


  componentDidMount() {
    if (!this.state.areas.length) {
      let url = process.env.GATSBY_STRAPI_FEED_SRC + "/areas?_limit=-1" // process.env.GATSBY_STRAPI_SRC + "/areas"; // TODO: base URL supposed to be from .env

      //this.getitems(url)
      //this.setState({value: this.props.areaVal})
    }

  }

  render() {
    const { value, suggestions, isChages, aliasVal } = this.state;

    // Autosuggest will pass through all these props to the input.
    var setVal = value;
    var setAliasVal = aliasVal;
    if (_.isEmpty(setVal) && !isChages && !_.isEmpty(this.props.areaVal)) {
      setVal = this.props.areaVal;
      setAliasVal = this.props.areaVal
    }

    const inputProps = {
      placeholder: this.props.placeHolder,
      value: setVal,
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
      autocomplete: "none",
      className: `form-control ${this.props.additionalclass}`
    };

    // Finally, render it!
    return (
      <div className="filter-search">
        <Autosuggest
          id="react-autosuggest-2"
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          onSuggestionSelected={this.suggestionSelected}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        // alwaysRenderSuggestions={true}
        />
        <i className="icon-pin"></i>
        <input type="hidden" className="searchVal" autocomplete="none" name="head-search-address-val" value={value || aliasVal} />
      </div>
    );
  }
}

export default SearchResultBox;

import React from 'react';
import { useEffect } from 'react';
import { graphql } from 'gatsby'

import Modules from "../components/home-modules"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";
import "../scss/custom.scss";



const HomeTemplate = (props) => {

  const GQLPage = props.data.glstrapi?.article;
  const Page_Modules = props.data.glstrapi?.article?.Modules;

  useEffect(() => {
    if (window) {
      window.scroll({
        top: -100,
        behavior: 'smooth'
      });
    }
  },[])
  
  let custom_css = ''
  let additional_class = ''
  if (GQLPage.Custom_CSS_Class) {
    custom_css = GQLPage.Custom_CSS_Class
  }

  if (GQLPage.Layout === 'Staticpage') {
    additional_class = 'static-page-detail'
  }

  return (
    <div className={`${custom_css} ${additional_class}`}>
      <Modules page={GQLPage} custom_css={custom_css} modules={Page_Modules} {...props} />
    </div>
  )
}
export default HomeTemplate;

export const pageQuery = graphql`
  query homeQuery($id: ID!) {
      glstrapi {
        article(id: $id, publicationState: LIVE) {
            Banner_CTA_1_Label
            Banner_CTA_1_Label_Heading
            Banner_CTA_2_Label
            Banner_CTA_2_Label_Heading
            Banner_Content
            Custom_CSS_Class
            Layout
            Meta_Description
            Meta_Title
            Pagename
            Show_Brands_Logo
            Show_Reviews
            Popular_Search
            id
            imagetransforms
            Choose_Menu {
              id
              URL
              Alias
            }
            Banner_CTA_1_Link {
              id
            }
            Banner_CTA_2_Link {
              id
            }
            Banner_Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 1800
                    quality: 100
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  ) 
                }
              } 
            }
            Modules {
              ... on GLSTRAPI_ComponentModulesIntro {
                id
                Intro_CTA_Label
                Intro_CTA_Form
                Intro_Content
                Intro_Title
                Intro_Video_URL
                Image_Right_Align
                Intro_Image {
                  alternativeText
                  url
                  id
                  url_sharp {
                    childImageSharp {
                      gatsbyImageData(
                        formats: WEBP
                        width: 700
                        quality: 100
                        layout: CONSTRAINED
                        transformOptions: { cropFocus: CENTER, fit: COVER }
                      )
                    }
                  } 
                }
                Intro_CTA_Link {
                  id
                }
              }
              ... on GLSTRAPI_ComponentModulesServicesTiles {
                Services_Background_Title
                Add_Service_Tile {
                  Title
                  Tile_Background_Image {
                    url
                    alternativeText
                    url_sharp {
                      childImageSharp {
                        gatsbyImageData(
                          formats: [AUTO,WEBP]
                          width: 350
                          height: 320
                          quality: 90
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER, fit: COVER }
                        )  
                      }
                    } 
                  }
                  Link {
                    id
                  }
                }
                Services_Content
              }
              ... on GLSTRAPI_ComponentModulesStatistics {
                Statistics_Title
                About_Statistics
                Add_Stats {
                  Count
                  Details
                }
                Statistics_Left_CTA_Label
                Statistics_Left_Content
                Statistics_Left_Title
                Statistics_Left_CTA_Link {
                  id
                }
              }
              ... on GLSTRAPI_ComponentModulesIntroWithoutImage {
                id
                Intro_CTA_Label
                Intro_Content
                Intro_Heading
                Intro_Title
                Intro_CTA_Link {
                  id
                }
              }
              ... on GLSTRAPI_ComponentModulesShowHelpModule {
                id
                Select_Module {
                  CTA_1_Label
                  CTA_2_Label
                  Content
                  Name
                  Show_Chart
                  CTA_1_Link {
                    id
                  }
                  CTA_2_Link {
                    id
                  }
                  Select_Form {
                    Select
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesLatestNews {
                Latest_News_Heading
                Latest_News_Intro_Content
              }
              ... on GLSTRAPI_ComponentModulesGlobalModules {
                Choose_Module
              }
              ... on GLSTRAPI_ComponentModulesServicesContentBlock {
                Add_New_Block {
                  Title
                  Content
                  CTA_Label  
                  CTA_Form
                  CTA_Link {
                    id
                  }
                  Image {
                    alternativeText
                    url
                    url_sharp {
                      childImageSharp {
                        gatsbyImageData(
                          formats: [AUTO,WEBP]
                          width: 700
                          quality: 90
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER, fit: COVER }
                        )
                      }
                    }
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesStaticContent {
                id
                Add_Static_Content
                Style
                Show_Search_In_Sidebar
                Sidebar_Custom_CTA_Label
                Sidebar_Custom_CTA_Link {
                  id
                  URL
                }
              }
              ... on GLSTRAPI_ComponentModulesCollections {
                Select_Collection
              }
              ... on GLSTRAPI_ComponentModulesBenefitsPointsWithIcon {
                Benefits_Intro
                Benefits_Disclaimer
                Add_Benefit {
                  Content
                  Icon
                  or_Icon_Image {
                    url
                    alternativeText
                  }
                  CTA_Label
                  CTA_Link {
                    id
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesSelectGuidesCategoryToShow {
                Select_Guide_Category {
                  id
                  Name
                  URL
                }
              }
              ... on GLSTRAPI_ComponentModulesFaqSSection {
                FAQ_Title
                Select_FAQs {
                  Answer
                  Question
                }
              }
              ... on GLSTRAPI_ComponentModulesGuidesSection {
                Guides_Heading
                Guides_Title
                More_Guides_Link {
                  Details_Page_URL {
                    id
                  }
                }
                Select_Guides_here {
                  Title
                  URL
                  Tile_Image {
                    alternativeText
                    url
                  }
                  Category {
                    Details_Page_URL {
                      id
                      URL
                    }
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesAccordion {
                id
                Accordion_Intro_Content
                Add_Accordion {
                  Accordion_Content
                  Accordion_Unique_ID
                  Accordion_Title
                }
              }
              ... on GLSTRAPI_ComponentModulesValuationTypes {
                Home_Visit_Valuation
                Instant_Valuation
              }
              ... on GLSTRAPI_ComponentModulesCustomerStories {
                id
                Stories_Title
                Add_Customer_Story {
                  Video_URL
                  Image {
                    alternativeText
                    url
                    url_sharp {
                      childImageSharp {
                        gatsbyImageData(
                          formats: [AUTO,WEBP]
                          width: 440
                          quality: 90
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER, fit: COVER }
                        )
                      }
                    } 
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesCaseStudiesSection {
                id
                Case_Studies_Heading
                Case_Studies_Title
                Select_Case_Studies_to_Show(sort: "Date:desc") {
                  Address
                  Title
                  URL
                  Tile_Image {
                    alternativeText
                    url
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesMultiColumnContent {
                Section_Intro
                Add_New_Column {
                  CTA_Label
                  Content
                  CTA_Link {
                    id
                  }
                  Image {
                    url
                    alternativeText
                    url_sharp {
                      childImageSharp {
                        gatsbyImageData(
                          formats: [AUTO,WEBP]
                          width: 700
                          quality: 90
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER, fit: COVER }
                        )
                      }
                    } 
                  }
                  Video_URL
                }
              }
              ... on GLSTRAPI_ComponentModulesAccreditation {
                Add_Accreditation {
                  Title
                  URL
                  Content
                  Image {
                    url
                    alternativeText
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesShowFeedStatistics {
                Stats_Title
              }
              ... on GLSTRAPI_ComponentModulesBrandsGrid {
                Brands_Main_Heading
                Brands_Intro
                Add_Brand {
                  CTA_Label
                  Content
                  Title
                  CTA_Link {
                    id
                  }
                  Image {
                    alternativeText
                    url
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesDisclaimerText {
                Add_Disclaimer_Content
              }
            }
          }
      }
  }
`